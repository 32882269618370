
import { showError, showSuccess } from '@/helpers/notifications'
import axios from '@/utils/axios'
import { BButton, BCard } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    BButton,
    BCard,
  },
})
export default class AdminPage extends Vue {
  async backup() {
    try {
      await axios.post('db/backup')
      showSuccess('Резервное копирование завершено')
    } catch (error) {
      console.error(error)
      showError('В процесе резервного копирования произошла ошибка')
    }
  }
}
